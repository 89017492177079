import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from './core/services/auth.service';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import Swal from 'sweetalert2';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'TrueClaim Provider';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date;
  isIdle = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private idle :Idle,
    private keepalive:Keepalive,
    public dialog: MatDialog,
    private swUpdate: SwUpdate,
    ) {
    if(sessionStorage.getItem('token')) {
      const token = JSON.parse(sessionStorage.getItem('token') || '{}');
      // this.authService.currentUser.next(token);
      localStorage.setItem('current_user_value',JSON.stringify(token));
      sessionStorage.clear();
    }
   
    this.idle.setIdle(300);

    // sets a timeout period of 30 seconds. after 10 minutes 30 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(30);

    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
    });

    this.idle.onTimeout.subscribe(() => {
      this.close();
      let userId = localStorage.getItem('id');
      if (userId) {
        Swal.fire('Session Timed Out! Please login again', '', 'info');
        this.idleState = 'Timed out!';
        this.timedOut = true;
        this.idle.stop();
        this.logout();
      }
    });

    this.idle.onIdleStart.subscribe(() => {
      let userId = localStorage.getItem('id');
      if (userId) {
        console.log('You\'ve gone idle!')
        this.idleState = 'You\'ve gone idle!';
        this.openDialog(this.idleState);
      }
    });

    this.idle.onTimeoutWarning.subscribe((countdown:any) => {
      let userId = localStorage.getItem('id');
      if (userId) {
        this.idleState = 'You will time out in ' + countdown + ' seconds!';
      }
    });

    this.idle.onInterrupt.subscribe(
      (data: any) => {
      
        this.isIdle = false;
      }
    );

    this.keepalive.interval(30);

    this.keepalive.onPing.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.lastPing = new Date();
    });

    this.idle.watch();
    this.timedOut = false;
  }

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {  
      this.swUpdate.available.subscribe(() => {
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue =true;
  }
  
  @HostListener('window:beforeunload', ['$event'])
  beforeunload($event: any) {
  console.log('$event :', $event);
    if (!this.authService.currentUserValue) {
      $event.returnValue = true;
      this.authService.logoutService();
    } else {
      sessionStorage.setItem('token', JSON.stringify(this.authService.currentUserValue));
    }
  }
  openDialog(idleState:any): void {
    const dialogRef = this.dialog.open(IdleSessionDialog, {
      data: idleState
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  close() {
    this.dialog.closeAll();
  }
  logout() {
    this.authService.logoutService();
  }
  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
}


@Component({
  selector: 'idle-session-dialog',
  templateUrl: 'idleSessionDialog.html',
})
export class IdleSessionDialog {

  constructor(
    private authService:AuthService,
     private router: Router,
    public dialogRef: MatDialogRef<IdleSessionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    // this.dashboardHomeService.refreshToken(localStorage.getItem('refreshToken')).subscribe(
    //   newToken => {
    //     localStorage.setItem('token', newToken.body.accessToken);
    //   }
    // )
    this.dialogRef.close();
  }

  logout() {
    this.authService.logoutService();
  }

}
