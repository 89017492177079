import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

constructor() { }

generateEncryption(value: any) {
  const keySize = 256;
  const iterations = 100;
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  const key = CryptoJS.PBKDF2('vote for NDM', salt, {
    keySize: keySize / 32,
    iterations: iterations
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encryptedValue = CryptoJS.AES.encrypt(value, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  const encryption = salt.toString() + iv.toString() + encryptedValue.toString();
  return encryption.toString();
}

}
